import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const CategoryDropDown = ({ handleCategoryId }) => {
  const [currentValue, setCurrentValue] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const categoryList = [
    { categoryId: 1, name: "커피" },
    { categoryId: 2, name: "논커피" },
    { categoryId: 3, name: "아이스크림" },
  ];

  const wrapperRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  const clickOption = (categoryId) => {
    // setLocation(index)
    setShowOptions((prev) => !prev);
    setCurrentValue(categoryId);
    handleCategoryId(categoryId);
  };

  return (
    <SelectBox
      show={showOptions}
      ref={wrapperRef}
      onClick={() => setShowOptions((prev) => !prev)}
    >
      <LabelContainer>
        <Label>
          {currentValue !== null
            ? categoryList[currentValue - 1].name
            : "Category"}
        </Label>
      </LabelContainer>
      <SelectOptions show={showOptions}>
        {categoryList.map((item, index) => (
          <CheckboxContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <StockLabel
              key={index}
              onClick={() => clickOption(item.categoryId)}
            >
              {item.name}
            </StockLabel>
          </CheckboxContainer>
        ))}
      </SelectOptions>
    </SelectBox>
  );
};

export default CategoryDropDown;

const SelectBox = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  position: relative;
  width: 130px;
  height: 47px;
  padding: 8px;
  border-radius: 16px;
  background-color: #ffffff;
  align-self: center;
  border: 1px solid #cccccc;

  cursor: pointer;
  content: "⌵";

  &::before {
    content: "⌵";
    position: absolute;
    top: ${(props) => (props.show ? "22px" : "16px")};
    right: 8px;
    color: gray;
    font-size: 20px;
    transform: ${(props) => (props.show ? "rotate(180deg)" : "rotate(0)")};
  }

  &:hover {
    border-color: gray; /* 마우스를 올릴 때 테두리 색상 변경 */
  }
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 170px;
  overflow: hidden;
`;

const Label = styled.div`
  display: block;
  justify-content: center;

  font-size: 20px;
  margin-top: 3px;
  text-align: center;
  color: gray;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SelectOptions = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  border: ${(props) =>
    props.show ? "1px solid #cccccc" : "0px solid #cccccc"};
  list-style: none;
  top: 50px;
  left: 0;
  width: 100%;
  overflow: hidden;
  //   height: 180px;

  max-height: ${(props) => (props.show ? "none" : "0")};
  padding: 0;
  background-color: white;
  border-radius: 16px;
  color: black;
`;

const StockLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 15px;
  font-size: 20px;
  font-weight: normal;
  background: ${(props) => (props.isChecked ? "#DDDDDD" : "6px")};

  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: #dddddd;
    border-radius: 15px;
    font-weight: bold;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  margin-top: 2px;
  //   background: red;
  width: 100%;
  height: 60px;
`;
