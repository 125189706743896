import React, { useEffect, useState } from "react";
import { ButtonReDoProvider } from "../../ButtonReDoContext";
import axios from "axios";
import styled from "styled-components";
import Swal from "sweetalert2";
import firebase from "firebase/compat/app";
import "firebase/compat/remote-config";

export default function App() {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(null);
  const [MenuData, setMenuData] = useState([]);
  const [testData, setTestData] = useState([]);
  const [remoteConfigStatus, setRemoteConfigStatus] = useState(null);

  // const [etag, setEtag] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectStatus, setSelectStatus] = useState(null);
  const redirectUri = `${process.env.REACT_APP_API_SERVER_URL}/appcontrol`; // 서버용

  useEffect(() => {
    // const isDevelopment = process.env.NODE_ENV === "development"; // 개발 환경 체크

    // if (!isDevelopment) {
    //   try {
    //     const clientId =
    //       "264368000672-tlvoq9gd8v6t3tjr91jlaino4cn4b6vt.apps.googleusercontent.com";
    //     const redirectUri = `${process.env.REACT_APP_API_SERVER_URL}/appcontrol`;

    //     const scope = "https://www.googleapis.com/auth/cloud-platform";
    //     const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(
    //       scope
    //     )}&response_type=token`;

    //     const url =
    //       "https://firebaseremoteconfig.googleapis.com/v1/projects/robros-ab510/remoteConfig";
    //     const token = localStorage.getItem("AccessToken");
    //     let etag = "";

    //     axios
    //       .get(url, {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       })
    //       .then((response) => {
    //         const data =
    //           response.data.parameters.INSPECTION_STATUS.defaultValue.value ===
    //           "true";
    //         setRemoteConfigStatus(data);
    //       })
    //       .catch((error) => {
    //         if (error.response && error.response.status === 401) {
    //           window.location.href = authUrl;
    //         }
    //       });
    //   } catch (error) {
    //     console.error("Error fetching remote config:", error);
    //   }
    // } else {
    //   // 개발 환경에서는 인증 로직을 스킵하고 기본 값 설정
    //   console.log("Running in development mode. Skipping authentication...");
    //   setRemoteConfigStatus(false); // 기본값으로 설정
    // }

    try {
      // 특정 구성 키 값을 업데이트
      const clientId =
        "264368000672-tlvoq9gd8v6t3tjr91jlaino4cn4b6vt.apps.googleusercontent.com";

      // 배포하면 바꿔야할 곳
      // const redirectUri = redirectUri;
      const redirectUri = "https://robros.net/appcontrol";
      //const redirectUri = "http://locaolhost:3000/appcontrol";

      const scope = "https://www.googleapis.com/auth/cloud-platform";

      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(
        scope
      )}&response_type=token`;
      console.log("Access Token:", authUrl);

      const url =
        "https://firebaseremoteconfig.googleapis.com/v1/projects/robros-ab510/remoteConfig";
      const token = localStorage.getItem("AccessToken");
      let etag = "";

      const aData = axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // const data = response.data.parameters.isHidden.defaultValue.value === "true";
          const data =
            response.data.parameters.INSPECTION_STATUS.defaultValue.value ===
            "true";
          setRemoteConfigStatus(data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            // 사용자를 인증 페이지로 리다이렉트
            window.location.href = authUrl;
            const hash = window.location.hash;

            const hashParams = new URLSearchParams(hash.substr(1));
            const newAccessToken = hashParams.get("access_token");

            // 이전에 저장된 AccessToken 값 가져오기
            let accessToken = localStorage.getItem("accessToken");

            if (accessToken == null) {
              localStorage.setItem("AccessToken", newAccessToken);
            } else {
              // 수정된 값으로 AccessToken 업데이트
              accessToken = newAccessToken;

              // 수정된 값으로 로컬 스토리지에 저장
              localStorage.setItem("AccessToken", accessToken);
            }
          }
        });
    } catch (error) {
      console.error("Error fetching remote config:", error);
    }

    const hash = window.location.hash;

    // 추출된 해시 부분을 파싱합니다.
    const hashParams = new URLSearchParams(hash.substr(1));

    // 액세스 토큰을 가져옵니다.
    const accessToken = hashParams.get("access_token");

    console.log("Access Token:", accessToken);

    axios
      .get(
        "https://zrtnrs2578.execute-api.ap-northeast-2.amazonaws.com/beta/check_status"
      )
      .then((response) => {
        console.log(response.data);
        setIsUnderMaintenance(response.data.status); // 받아온 데이터의 "status" 값을 상태 변수에 저장
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        "https://zrtnrs2578.execute-api.ap-northeast-2.amazonaws.com/beta/bty_menu_soldout_fn",
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 서버에서 허용할 도메인 설정
          },
          mode: "cors", // CORS 요청 설정
        }
      )

      .then((response) => {
        console.log(response.data);
        setMenuData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleButtonTrueClick = () => {
    axios
      .patch(
        "https://zrtnrs2578.execute-api.ap-northeast-2.amazonaws.com/beta/check_status/true",
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 서버에서 허용할 도메인 설정
          },
          mode: "cors", // CORS 요청 설정ss
        }
      )
      .then((response) => {
        console.log(response.data);
        window.location.reload(); // 페이지 새로고침
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleButtonFalseClick = () => {
    axios
      .patch(
        "https://zrtnrs2578.execute-api.ap-northeast-2.amazonaws.com/beta/check_status/false",
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 서버에서 허용할 도메인 설정
          },
          mode: "cors", // CORS 요청 설정
        }
      )
      .then((response) => {
        console.log(response.data);
        window.location.reload(); // 페이지 새로고침
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 리스트에 JSON 값을 추가하는 함수
  const addItemToList = (item) => {
    // 리스트에 중복된 menuCd 값이 있는지 확인
    const existingItemIndex = testData.findIndex(
      (existingItem) => existingItem.menuCd === item.menuCd
    );

    if (existingItemIndex === -1) {
      // 중복된 menuCd 값이 없으면 새로운 아이템을 추가
      setTestData((prevList) => [...prevList, item]);
    } else {
      // 중복된 menuCd 값이 있으면 해당 아이템을 새 아이템으로 교체
      var item111 = {
        menuCd: item.menuCd,
        updatedSoldout: item.updatedSoldout,
        soldout: testData[existingItemIndex].soldout,
      };
      setTestData((prevList) => {
        const updatedList = [...prevList];
        updatedList[existingItemIndex] = item111;
        return updatedList;
      });
      console.log(
        `Item with name '${item.name}' already exists in the list. Replaced with new item.`
      );
    }
  };

  const handleButtonMenuClick = (categoryName, nameKor, item1) => {
    // items 배열을 순회하며 nameKor 값과 일치하는 객체를 찾습니다
    const updatedMenuData = MenuData.map((category) => {
      if (category.name === categoryName) {
        // 해당 카테고리에서 nameKor 값이 일치하는 메뉴 객체를 찾습니다
        const updatedItems = category.items.map((item) => {
          if (item.temperature[0].nameKor === nameKor) {
            // soldout 상태를 변경합니다
            var cnt = 0;
            cnt++;

            const testSoldoutValue = {
              menuCd: item.id,
              updatedSoldout: !item.soldout,
              soldout: item.soldout,
            };

            addItemToList(testSoldoutValue);
            console.log("test");

            return {
              ...item,
              soldout: !item.soldout,
            };
          }

          return item;
        });
        // 변경된 메뉴 객체들로 카테고리를 업데이트합니다
        return {
          ...category,
          items: updatedItems,
        };
      }
      return category;
    });
    // 변경된 메뉴 데이터를 업데이트합니다
    setMenuData(updatedMenuData);
    // console.log(updatedMenuData);
  };

  const handleButtonApplyClick = () => {
    // 바뀐 값들만 걸러네는 작업.
    var finalResult = [];

    for (let i = 0; i < testData.length; i++) {
      if (testData[i].soldout !== testData[i].updatedSoldout) {
        const val = {
          menuCd: testData[i].menuCd,
          soldout: testData[i].updatedSoldout,
        };
        finalResult.push(val);
      }
    }

    console.log("최종");
    console.log(finalResult);
    const result = {
      result: finalResult,
    };
    console.log(result);

    // 빈 리스트일때 예외처리 필요함!

    // 수정된 MenuData를 서버에 보냄
    axios
      .patch(
        "https://zrtnrs2578.execute-api.ap-northeast-2.amazonaws.com/beta/bty_menu_soldout_fn",
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 서버에서 허용할 도메인 설정
          },
          data: result, // 수정된 MenuData를 요청의 데이터로 전달
          mode: "cors", // CORS 요청 설정
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "반영 완료",
          // text: "삭제",
          confirmButtonText: "확인",
        });
        console.log(response.status);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        // 오류 발생 시 처리 작업 수행
        Swal.fire({
          icon: "error",
          title: "반영 실패",
          // text: "삭제",
          confirmButtonText: "확인",
        });
      });
  };

  const handleButtonFetchConfig = async (value) => {
    try {
      // 특정 구성 키 값을 업데이트
      // const clientId = '264368000672-vckg73rpf6bgl8vb5aaclma4d2su1t3a.apps.googleusercontent.com';
      const clientId =
        "264368000672-tlvoq9gd8v6t3tjr91jlaino4cn4b6vt.apps.googleusercontent.com";

      // 배포하면 바꿔야할 곳
      const scope = "https://www.googleapis.com/auth/cloud-platform";

      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(
        scope
      )}&response_type=token`;
      console.log("Access Token:", authUrl);

      const url =
        "https://firebaseremoteconfig.googleapis.com/v1/projects/robros-ab510/remoteConfig";
      const token = localStorage.getItem("AccessToken");
      let etag = "";

      const aData = axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const data = response.data;

          var currentEtag = response.headers.etag;
          etag = currentEtag;

          console.log(" response.data.parameters :", response.data.parameters);
          data.parameters.INSPECTION_STATUS.defaultValue = {
            value: `${value}`,
          };

          axios
            .put(url, data, {
              headers: {
                "Content-Type": "application/json; UTF8",
                "If-Match": etag,
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              console.log("Remote config updated successfully:", response.data);
              console.log(
                "isHidden : ",
                response.data.parameters.INSPECTION_STATUS.defaultValue.value
              );
              const data =
                response.data.parameters.INSPECTION_STATUS.defaultValue
                  .value === "true";

              setRemoteConfigStatus(data);
            })
            .catch((error) => {
              console.error(
                "Error updating remote config:",
                error.response.data
              );
            });
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            // 사용자를 인증 페이지로 리다이렉트
            window.location.href = authUrl;
            const hash = window.location.hash;

            const hashParams = new URLSearchParams(hash.substr(1));
            const newAccessToken = hashParams.get("access_token");

            // 이전에 저장된 AccessToken 값 가져오기
            let accessToken = localStorage.getItem("accessToken");

            if (accessToken == null) {
              localStorage.setItem("AccessToken", newAccessToken);
            } else {
              // 수정된 값으로 AccessToken 업데이트
              accessToken = newAccessToken;

              // 수정된 값으로 로컬 스토리지에 저장
              localStorage.setItem("AccessToken", accessToken);
            }
          }
        });
    } catch (error) {
      console.error("Error fetching remote config:", error);
    }
  };

  function MyVerticallyCenteredModal(props) {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      setIsOpen(props.show);
    }, [props.show]); // props.show 값이 변경될 때마다 모달 열기/닫기 상태 업데이트

    const closeModalHandler = () => {
      setModalShow(false);
    };

    const confirmlHandler = () => {
      setModalShow(false);
      handleButtonFetchConfig(selectStatus);
    };

    return (
      <>
        <ModalContainer>
          {isOpen && (
            <ModalBackdrop onClick={closeModalHandler}>
              <ModalView onClick={(e) => e.stopPropagation()}>
                변경하시겠습니까?
                <ButtonContainer>
                  <HandleButton onClick={confirmlHandler}>확인</HandleButton>
                  <HandleButton onClick={closeModalHandler}>취소</HandleButton>
                </ButtonContainer>
              </ModalView>
            </ModalBackdrop>
          )}
        </ModalContainer>
      </>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <MyVerticallyCenteredModal
        modal={modalShow}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          background: "white",
          marginBottom: "-40px",
        }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 0,
          }}
        >
          <LabelStatus>
            {isUnderMaintenance === true
              ? "앱 활성화 상태입니다."
              : isUnderMaintenance === false
              ? "앱 비활성화 상태입니다."
              : "데이터를 불러오는 중입니다."}
          </LabelStatus>
          <ButtonTRUE onClick={handleButtonTrueClick}>TRUE</ButtonTRUE>
          <ButtonFALSE onClick={handleButtonFalseClick}>FALSE</ButtonFALSE>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            background: "white",
          }}
        >
          <LabelStatus>
            {remoteConfigStatus === true ? "앱 비활성화" : "앱 활성화"}
          </LabelStatus>
          <ButtonTRUE
            onClick={() => {
              setModalShow(true);
              setSelectStatus(false);
            }}
          >
            활성화
          </ButtonTRUE>
          <ButtonFALSE
            onClick={() => {
              setModalShow(true);
              setSelectStatus(true);
            }}
          >
            비활성화
          </ButtonFALSE>
        </div>
      </div>

      <LineRow> </LineRow>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <ButtonEX
          style={{ marginLeft: "20px", background: "#b7d0f7" }}
        ></ButtonEX>
        <label style={{ marginLeft: "10px" }}> : Soldout , </label>
        <ButtonEX
          style={{ marginLeft: "20px", background: "#ebf1fa" }}
        ></ButtonEX>
        <label style={{ marginLeft: "10px" }}> : Not Soldout </label>
      </div>

      <div style={{ width: "1100px", height: "400px" }}>
        <Container
          style={{
            overflowY: "scroll",
            width: "1100px",
            height: "400px",
            scrollbarWidth: "thin",
          }}
        >
          {MenuData.map((category, index) => (
            <BoxCategory
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
              key={index}
            >
              <LabelCategory>{category.name}</LabelCategory>
              {/* items 배열을 순회하며 temperature의 nameKor 값을 출력 */}
              {category.items.map((item) => (
                <ButtonMenu
                  key={item.id}
                  style={{
                    backgroundColor: item.soldout ? "#b7d0f7" : "#ebf1fa",
                  }}
                  onClick={() =>
                    handleButtonMenuClick(
                      category.name,
                      item.temperature[0].nameKor,
                      item
                    )
                  }
                >
                  {item.temperature[0].nameKor}
                </ButtonMenu>
              ))}
            </BoxCategory>
          ))}
        </Container>
      </div>
      <LineRow> </LineRow>
      <ButtonApply onClick={handleButtonApplyClick}>적용하기</ButtonApply>
    </div>
  );
}

const BoxCategory = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin: 0 0 0 20px;
  flex-direction: column;
  height: 400px;
  // overflow: hidden;
`;

const ButtonMenu = styled.button`
  display: block;

  font-size: 20px;
  color: black;
  padding: 15px 15px 15px 14px;
  width: 200px; /* 너비 고정 */
  height: 50px;
  border-radius: 8px;
  font-weight: normal;
  background-color: #d9d9d9;
  margin: 10px;
  text-align: center;
  text-overflow: ellipsis; /* 넘치는 부분을 ...으로 표시 */
  white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  overflow: hidden;

  border: 0px solid black;

  transition: box-shadow 0.3s ease; /* 그림자 효과에 트랜지션 추가 */

  &:hover {
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0.1px 1px 4px 4px rgba(0, 0, 0, 0.1); /* 마우스가 올려졌을 때 그림자 효과 추가 */
    overflow: visible;
  }
`;

const ButtonEX = styled.button`
  width: 50px;
  height: 20px;
  border-radius: 8px;
  background-color: #d9d9d9;
  border: 0px solid white;
`;

const LabelCategory = styled.div`
  justify-content: center;
  display: flex;
  text-align: center;
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin: 10px 0 0 px;
  background: white;
  border-bottom: 3px solid black;
`;

const LabelStatus = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-radius: 8px;
  width: 200px;
  height: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin: 30px 0 40px 50px;
  background: white;
  box-shadow: 0.1px 1px 4px 3px rgba(0, 0, 0, 0.1);
`;

const LineRow = styled.div`
  width: 1000px;
  height: 2px;
  background: #d9d9d9;
  margin: 20px 0 0 30px;
`;

const LineColumn = styled.div`
  width: 2px;
  height: 1000px;
  background: #d9d9d9;
  margin: 20px 0 0 30px;
`;

const ButtonTRUE = styled.button`
  font-size: 20px;
  color: black;
  margin: 30px 0 0 20px;
  width: 100px;
  height: 50px;
  border-radius: 8px;
  font-weight: normal;
  background: #ebf1fa;
  border: 0px;

  transition: box-shadow 0.3s ease; /* 그림자 효과에 트랜지션 추가 */

  &:hover {
    background: #b7d0f7;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0.1px 1px 4px 4px rgba(0, 0, 0, 0.1); /* 마우스가 올려졌을 때 그림자 효과 추가 */
  }
`;

const ButtonFALSE = styled.button`
  font-size: 20px;
  color: black;
  margin: 30px 0 0 10px;
  width: 100px;
  height: 50px;
  border-radius: 8px;
  font-weight: normal;
  background: #ebf1fa;
  border: 0px;

  transition: box-shadow 0.3s ease; /* 그림자 효과에 트랜지션 추가 */

  &:hover {
    cursor: pointer;
    background: #b7d0f7;

    font-weight: bold;
    box-shadow: 0.1px 1px 4px 4px rgba(0, 0, 0, 0.1); /* 마우스가 올려졌을 때 그림자 효과 추가 */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const ButtonApply = styled.button`
  font-size: 24px;
  color: black;
  height: 50px;
  width: 200px;
  border-radius: 8px;
  font-weight: normal;
  background-color: #b7d0f7;
  margin: 20px 0px 10px 30px;
  border: 0px solid black;

  transition: box-shadow 0.3s ease; /* 그림자 효과에 트랜지션 추가 */
  position: sticky;
  &:hover {
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0.1px 1px 4px 4px rgba(0, 0, 0, 0.1); /* 마우스가 올려졌을 때 그림자 효과 추가 */
  }
`;

const ModalContainer = styled.div`
  // TODO : Modal을 구현하는데 전체적으로 필요한 CSS를 구현합니다.
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ModalBackdrop = styled.div`
  // TODO : Modal이 떴을 때의 배경을 깔아주는 CSS를 구현합니다.
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalBtn = styled.button`
  background-color: #45579c;
  text-decoration: none;
  border: none;
  padding: 20px;
  color: white;
  border-radius: 30px;
  cursor: grab;
`;

const HandleButton = styled.button`
  background: #ebf1fa;
  width: 120px;
  height: 50px;
  border-radius: 15px;
  color: black;
  font-size: 1rem;
  border: none; 
  margin-left: 16px;
  margin-right: 14px;

  transition: box-shadow 0.3s ease; 

  &:hover {
    cursor: pointer;
    background: #b7d0f7;

    font-weight: bold;
    box-shadow: 0.1px 1px 4px 4px rgba(0, 0, 0, 0.1);  
`;

const ButtonContainer = styled.div`
  background: white;
  display: flex;
  width: 100%;
  height: 60px;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  margin-top: 40px;
  margin-bottom: -40px;
`;

const ModalView = styled.div.attrs((props) => ({
  // attrs 메소드를 이용해서 아래와 같이 div 엘리먼트에 속성을 추가할 수 있습니다.
  role: "dialog",
}))`
  width: 300px;
  height: 150px;
  padding: 40px;

  background-color: white;
  border: none;
  border-radius: 30px;

  text-align: center;
  color: #121924;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > button {
    background: #ebf1fa;
    width: 20%;
    height: 20%;
    border-radius: 20px;
    color: black;
    font-size: 1rem;
    border: none;
    margin-top: 20px;
    margin-bottom: -35px;
  }
`;
