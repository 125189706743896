import React, { useEffect, useState } from "react";
import LeftContainer from "./LeftContainer";
import { TabLayout } from "./TabLayout";
import { ButtonReDoProvider } from "../../ButtonReDoContext";

import socketOffImage from "../../assets/socketoff.png";
import socketOnImage from "../../assets/socketon.png";

export default function App() {
  const [socket, setSocket] = useState();
  const [message, setMessage] = useState("");
  const [teadata, setteadata] = useState("");
  const [socketStatus, setSocketStatus] = useState({
    image: socketOffImage,
    text: "ㅤSocket OFF",
  });

  useEffect(() => {
    const socketInstance = new WebSocket("wss://www.ondabang.com:7097/echo");
    // 개발 테스트용
    //const socketInstance = new WebSocket("ws://192.168.2:7096");

    socketInstance.onopen = function (event) {
      console.log("WebSocket cleaning mode 연결 성공");
      setSocket(socketInstance);
      setSocketStatus({ image: socketOnImage, text: "ㅤSocket ON" });

      let receivedData = null;
      socketInstance.onmessage = function (event) {
        if (receivedData === null) {
          receivedData = JSON.parse(event.data);
          setteadata(receivedData);
          console.log("서버에서 티데이터 수신:", receivedData);
        }
      };

      setInterval(() => {
        if (socketInstance.readyState === WebSocket.OPEN) {
          socketInstance.send("ping!");
          console.log("서버에 메시지 전송");
        }
      }, 30000);
    };

    return () => {
      socketInstance.close();
      console.log("WebSocket cleaning mode 연결 종료");
      setSocketStatus({ image: socketOffImage, text: "ㅤSocket OFF" });
    };
  }, []);

  useEffect(() => {
    return () => {
      if (socket !== undefined) {
        socket.close();
        console.log("WebSocket 연결 닫힘");
        setSocketStatus({ image: socketOffImage, text: "ㅤSocket OFF" });
      }
    };
  }, [socket]);

  return (
    <div
      style={{
        display: "flex",
        height: "800px",
        width: "1168px",
        marginTop: "2px",
      }}
    >
      <ButtonReDoProvider>
        <LeftContainer
          socket={socket}
          socketStatus={socketStatus}
          setSocketStatus={setSocketStatus}
          socketOnImage={socketOnImage}
          socketOffImage={socketOffImage}
        />
      </ButtonReDoProvider>

      <TabLayout
        socket={socket}
        setMessage={setMessage}
        message={message}
        teadata={teadata}
      />
    </div>
  );
}
